// 
// _table.scss
// 

.table {
  th {
      font-weight: $font-weight-bold;
  }
}

//Table centered
.table-centered {
  td,th {
      vertical-align: middle !important;
  }
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}